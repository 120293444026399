import React from "react";
import "./recipe-list.scss"
import Img from "gatsby-image";
import {Link} from "gatsby";

const RecipeList = (props) => {

const alias = props.entity.entityUrl.path;

return (
    <Link className="article-list-item" to={alias.substring(3)}>
      <Img fluid={{...props.entity.fieldImage.imageGatsbyFile.childImageSharp.fluid, sizes: '(max-width: 400px) 100px, (max-width: 800px) 200px, 400px'}} />
      <div className="article-info">
        <h2>{props.entity.entityLabel}</h2>
      </div>
    </Link>
  )
};

export default RecipeList;
