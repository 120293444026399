import React from "react";
import "./pager.scss"
import {Link} from "gatsby";

const Pager = (props) => {

  // A simple pager. We only show the numbers for now, no previous/next support yet.
  let pageButtons = {};
  for (let i = 0; i < props.numPages; i++) {
    let suffix = '';
    if (i > 0) {
      suffix = `/${i + 1}`;
    }
    pageButtons[i] = { suffix: suffix, label: i + 1 }
  }

  return (
    <div className="pager">
      <ul>
        {Object.entries(pageButtons).map(([ index, { suffix, label } ]) => {
          let link = `/recepten${suffix}`;
          return <li key={index}><Link to={link} activeClassName="active">{label}</Link></li>
        })}
      </ul>
    </div>
  );

};

export default Pager;
