import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Pager from "../components/navigation/pager";
import "./recipes.scss"
import RecipeList from "../components/node/recipe-list";

export default ({ data, pageContext }) => {


  return (
    <Layout language={pageContext.language}>
      <div className="blogs-overview ">
        <h1>Recepten</h1>
        {data.drupal.nodeQuery.entities.map((entity) => (
          <RecipeList
            key={entity.entityTranslation.entityId}
            entity={entity.entityTranslation}
          />
        ))}
      </div>
      <Pager {...pageContext}/>
    </Layout>
  )
};

export const query = graphql`
query($language: [String], $languageId: Drupal_LanguageId!, $skip: Int!, $limit: Int!) {
  drupal {
    nodeQuery(
      filter: {conditions: 
      [
        {field: "type", operator: EQUAL, value: "recipe"},
        {field: "langcode", operator: EQUAL, value: $language},
        {field: "status", operator: EQUAL, value: "1"}
      ]},
      limit: $limit,
      offset: $skip,
      sort: {field: "created", direction: DESC}) {
      entities {
        ... on Drupal_NodeRecipe {
          entityTranslation(language: $languageId) {
             ...RecipeTeaser
          }
        }  
      }
    }
  }
}
`;
